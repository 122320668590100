<template>
  <div class="timeline">
    <div
      v-for="(item, index) in timelineList"
      :key="index"
      class="timeline-list"
    >
      <div class="fold">
        <span>{{ item.titleData }}</span>
        <span class="arrow-bottom" />
      </div>
      <ul class="timeline-wrapper">
        <li v-for="(child, j) in item.detile" :key="j" class="timeline-item">
          <div class="timeline-box">
            <div class="out-circle">
              <div class="in-circle" />
            </div>
            <div class="long-line" />
          </div>
          <div class="timeline-content">
            <div class="timeline-date">{{ child.date }}</div>
            <div class="timeline-desc">{{ child.content }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  name: 'Timeline',
  props: {
    timelineList: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./../assets/scss/base.scss";
.timeline {
  display: flex;
  flex-wrap: wrap;
  .timeline-list {
    display: flex;
    &:nth-child(1) {
      margin-bottom: 24px;
    }
    .fold {
      color:#0062db;
      text-decoration: underline;
      font-size: 24px;
      margin-right: 30px;
      span:nth-child(1) {
        margin-right: 10px;
      }
    }
  }
}
ul.timeline-wrapper {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* 时间线 */
.timeline-item {
  overflow: hidden;
  // display: flex;

  .timeline-box {
    float: left;
    text-align: center;
    padding-top: 10px;

    .out-circle {
      width: 16px;
      height: 16px;
      background: rgba(14, 116, 218, 0.1);
      // box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
      /*opacity: 0.1;*/
      border-radius: 50%;
      display: flex;
      align-items: center;

      .in-circle {
        width: 8px;
        height: 8px;
        margin: 0 auto;
        background: rgba(14, 116, 218, 1);
        border-radius: 50%;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
      }
    }

    .long-line {
      width: 2px;
      height: 44px;
      background: rgba(14, 116, 218, 1);
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
      opacity: 0.1;
      margin-left: 8px;
    }
  }

  .timeline-content {
    cursor: pointer;
    &:hover {
      transition: all 0.5s;
      background-color: #eff0f2;
      .timeline-date,
      .timeline-desc {
        color: #0062db;
        font-size: 26px;
        font-weight: bold;
      }
    }
    transition: all 0.5s;
    overflow: hidden;
    line-height: 40px;
    border-radius: 6px;
    // height: 40px;
    // display: flex;
    box-sizing: border-box;
    margin-left: 40px;
    padding: 0 20px 0 20px;
    text-align: left;

    .timeline-title {
      font-size: 14px;
      word-break: break-all;
      margin-bottom: 16px;
      color: #333;
      font-weight: 500;
      /*display: inline;*/
    }

    .timeline-date {
      float: left;
      margin-right: 25px;
      font-size: 18px;
      color: #222;
      font-weight:bold;
      // margin-bottom: 16px;
    }
    .timeline-desc {
      float: left;
      width: 1000px;
      font-size: 16px;
      color: #777;
    }
  }
}

.timeline-item:last-of-type .timeline-content {
  margin-bottom: 0;
}
</style>
