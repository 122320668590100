<template>
  <div class="notes">
    <div class="line" />
    <h2>物联大事记</h2>
    <div class="time-line">
      <time-line :timeline-list="timelineList" />
    </div>
  </div>
</template>

<script>
import TimeLine from './../../components/TimeLIne.vue'
export default {
  components: {
    TimeLine
  },
  data() {
    return {
      timelineList: [
        {
          titleData: '2021',
          detile: [
            {
              date: '2021-12-09',
              content: '山水集团副总裁赵宏波莅临山水物联指导工作'
            },
            {
              date: '2021-11-20',
              content: '辽宁山水通途物联智能科技有限公司沈阳分公司成立'
            },
            {
              date: '2021-09-25',
              content: '山水集团总裁李会宝莅临山水物联检查指导工作'
            },
            {
              date: '2021-09-10',
              content:
                '突破10000单线上业务'
            },
            {
              date: '2021-08-06',
              content: '突破1000单线上业务'
            },
            {
              date: '2021-08-06',
              content:
                '辽宁山水通途物联智能科技有限公司营口分公司成立'
            },
            {
              date: '2021-07-20',
              content:
                '第1笔线上业务'
            }

          ]
        },
        {
          titleData: '2020',
          detile: [
            {
              date: '2020-12-21',
              content:
                '辽宁山水通途物联智能科技有限公司成立。'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import "./../../assets/scss/base.scss";
.notes {
  .time-line {
    margin-bottom: 200px;
    margin-left: 40px;
  }
}
</style>
